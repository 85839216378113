import React , { useEffect } from "react";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import banner from "./blogimg/impact-of-covid-on-eyewear-industry/banner-image.jpg";
import blog01 from "./blogimg/impact-of-covid-on-eyewear-industry/blog-01.jpg";
import blog02 from "./blogimg/impact-of-covid-on-eyewear-industry/blog-02.jpg";
import blog03 from "./blogimg/impact-of-covid-on-eyewear-industry/blog-03.jpg";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function Blog01() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });


  return (
    <div>
      <Helmet>
        <title>Impact of COVID on Eyewear Industry</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            background: "#ADBACC",
          }}
        >
          <div >
            <section className="container-fluid ">
              <div className="row aligner">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <br></br><br></br><br></br>
                  <h1 style={{ color: "#2B2B2B" }}>
                    IMPACT OF COVID ON <b>EYEWEAR INDUSTRY</b>
                  </h1>
                </div>

                <div className="col-md-6 noPadding">
                  <img
                    src={banner}
                    alt="THE BIGGEST EYEWEAR TRENDS OF 2021"
                    className="img-responsive"
                  ></img>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div style={{paddingTop:"60px"}}>
          <div className="container-fluid" data-aos="fade-left" data-aos-duration="400">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <img src={blog01} className="img-responsive"></img>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div
              className="row"
              style={{ paddingTop: "20px", paddingBottom: "20px" }}
            >
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <h2>Overview Of The Eyewear Market</h2>
              </div>
              <div className="col-md-7">
                <p>
                  The global eyewear market size is expected to reach USD 178.95
                  Billion by 2026; owing to increasing cases of deteriorating
                  eye health and the trendsetting launch of eyewear products by
                  manufacturers across the globe. It is expected to expand at a
                  compound annual growth rate (CAGR) of 8.5% from 2021 to 2028.{" "}
                </p>
              </div>
              <div className="col-md-1"></div>
            </div>
            <br></br>
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <h2
                  style={{
                    borderBottom: ".5px solid #707070",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  COVID-19 Crisis To Surge Exponential Demand
                </h2>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-5" data-aos="fade-right" data-aos-duration="400">
                      <img src={blog02} className="img-responsive"></img>
                    </div>
                    <div className="col-md-7" data-aos="fade-left" data-aos-duration="400">
                      <p>
                        <ul className="CustomList">
                          <li>
                            The business is predicted to see a boom shortly as a
                            result of the mayhem caused by Coronavirus,
                            COVID-19. Several government agencies have declared
                            a state of emergency to stem the spread of the
                            global pandemic, which is leading to increased
                            screen time for those confined to their homes.
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
                            Furthermore, digital overload is wreaking havoc on
                            people's eyes, prompting them to seek out glasses
                            that shield them from harmful blue light.{" "}
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
                            Due to COVID-19, there has been a significant
                            increase in the preference for buying eyewear across
                            online networks. Significant improvements in the
                            e-commerce supply chain and the growing confidence
                            of customers regarding online transactions have
                            further strengthened the growth in this segment.
                            Several online players are now offering improved
                            shopping experiences to users by leveraging advanced
                            technologies in their applications.{" "}
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
                            Furthermore, the rising popularity of targeted
                            advertising, as well as mandatory lockdowns imposed
                            by governments in several countries in response to
                            the rapid spread of Coronavirus disease (COVID-19),
                            are aiding market growth in the online segment.
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
            <div className="row" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <br></br> <br></br>
                <h2
                  style={{
                    borderBottom: ".5px solid #707070",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Revolution Of Eyewear Industry During COVID
                </h2>
                <img src={blog03} className="img-responsive"></img>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4">
                      <p><br></br><br></br>
                        <ul className="CustomList">
                          <li>
                            The spread of the Corona Virus has brought the whole
                            planet to a halt.
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
                            The Corona crisis has wreaked havoc on economies,
                            consumers, employees, and laborers, and has had a
                            devastating impact on every aspect of life; it is
                            one of the most difficult challenges the world has
                            ever faced.
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
                            No doubt, it has shaken eyewear businesses
                            unprecedentedly and has turned the lives of startup
                            owners upside down. According to Business Insider,
                            “there is a chance of a “Startup Depression,” in
                            which new firms do not enter the labor market as a
                            result of the pandemic.”
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
                            Yeah, now is the perfect time for the eyewear
                            industry to turn CHALLENGES into OPPORTUNITIES.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p><br></br><br></br>
                        <ul className="CustomList">
                          <li>
                          No doubt, this lockdown has thrown a lot of opportunities for online players for essential products and services.

                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
And among these, one of the most essential eye care products is EYEGLASSES.

</li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
One can’t compromise life chores without a pair of eyeglasses. Moreover, the lockdown and work from home culture have grown usage of digital devices in a significant manner, resulting in EYE DISORDERS and rising need for eyewear.

</li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
The eyewear industry has a lot of room to expand. Players in the eyewear industry are ensuring that vital eyeglasses meet those in need while also considering health and transitioning to the ONLINE OPERATIONS model. The eyewear industry has gained enormous growth in the past years. Remarkable opportunities are available in the eyewear sector.

</li>
                        </ul>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p><br></br><br></br>
                        <ul className="CustomList">
                          <li>
                          With the spread of Coronavirus, eyewear businesses have also got impacted in terms of low sales and low footfalls, but the players are trying hard to come up with new ways to keep their business running.
                          </li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
Providing essential eyewear to the customers, while facing the industry challenges due to the Corona crisis, is not an easy task but the companies are going the extra mile to minimize the disruption caused by COVID-19 and converting challenges into revenue opportunities by making the right strategies.
</li>
                          <br></br>
                          <br></br>
                          <br></br>
                          <li>
Yes, we may conclude that the Eyewear Industry is aware of the fact that when it comes to opportunities, never let a challenge go to waste.

                         
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
